const Qualetics = require("@deerden/qualeticssdk");

const reportid = Date.now().toString();
const qualetics = new Qualetics.service("lakesprofispace", "r2mfoZ0ykWnp", "RtjRal", false,
  {
    "host": 'wss://api.qualetics.com',
    "port": 443,
    "defaultActor": {
      "type": "User",
      "id": reportid
    },
    "stickySessionId": false
  }
);

qualetics.init();

const reportUsage = async (values) => {
  qualetics.send(values);
}

export default reportUsage;