import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import QuestionCard from '../../components/QuestionCard'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";


const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function Security() {
  return (
    <React.Fragment>
      <Helmet title="Security" />
      <Typography variant="h3" gutterBottom display="inline">
        Security
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>
          tools
        </Typography>
        <Typography>Security</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <QuestionCard path="/sales" linkName="Sales" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Security;
