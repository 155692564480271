import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Paper, Typography, Link, CircularProgress as MuiCircularProgress } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/profirator-logo-white.svg";
import { useSearchParams } from "react-router-dom";
import { spacing } from "@mui/system";

import axios from 'axios'

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 256px;
  height: 124px;
  margin-bottom: 32px;
`;

const Loading = styled(MuiCircularProgress)(spacing);


const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Verify() {
  const [searchParams] = useSearchParams()
  const [success, changeSuccess] = useState(null)
  const [loading, setLoading] = useState(true)

  const updateSuccess = (state) => {
    changeSuccess(state)

  }

  const updateLoading = () => {
    setLoading(false)
  }



  useEffect(() => {
    const postVerify = async (searchParams) => {

      try {

        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/keyrock/verify`,
          {
            "code": searchParams.get("verify_code"),
            "email": searchParams.get("email"),
            "ts": searchParams.get("ts")

          })
        updateSuccess(true)

      } catch {
        updateSuccess(false)

      } finally {
        updateLoading()

      }
    }
    postVerify(searchParams)

  }, [searchParams])

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title="Verify" />
        {loading === true ? <Loading /> :
          <>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              {success ? `Verifying succesful! Please login below:` : `Something went wrong with the verification process`}
            </Typography>

            <Typography align="center" variant="h4"> {<Link href="sign-in" align="center">Log in</Link>}</Typography>
          </>
        }

      </Wrapper>
    </React.Fragment>
  );
}

export default Verify;
