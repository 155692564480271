import React from "react";
import styled from "styled-components/macro";

import {
  CardContent,
  Link,
  Card as MuiCard,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";

const Card = styled(MuiCard)(spacing);


const QuestionCard = ({path, linkName}) => {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Sorry, this feature is not available yet in our PaaS. Please contact <Link
            component={NavLink}
            to={path}
            
          >
            {linkName}
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
}


export default QuestionCard;
