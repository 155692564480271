import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import GuestGuard from '../../components/guards/GuestGuard'
import { Avatar, Paper, Typography } from "@mui/material";

import { ReactComponent as LogoWhite } from "../../vendor/profirator-logo-black.svg";

import SignInComponent from "../../components/auth/SignIn";

const Brand = styled(LogoWhite)`
  fill: ${(props) => props.theme.palette.logo};
  width: 256px;
  height: 128px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;



function SignIn() {
  return (
    <GuestGuard>
      <React.Fragment>
        <Brand />
        <Wrapper>
          <Helmet title="Sign In" />
          <BigAvatar alt="Profile Picture" src="/static/img/avatars/avatar_placeholder.jpg" />

          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Welcome, please log in
          </Typography>
      
          <SignInComponent />
        </Wrapper>
      </React.Fragment>
    </GuestGuard>
  );
}

export default SignIn;
