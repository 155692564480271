import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import PresentationLayout from "./layouts/Presentation";

import DocLayout from "./layouts/Doc";

import Sales from './pages/sales/Sales'


// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Verify from "./pages/auth/Verify";

import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Tools pages
import Kubernetes from './pages/tools/Kubernetes'
import SmartCity from './pages/tools/SmartCity'
import AccessControl from './pages/tools/AccessControl'
import Connectors from './pages/tools/Connectors'
import Security from './pages/tools/Security'
import Analytics from './pages/tools/Analytics'


//ToS Pages
import TermsOfService from "./pages/policies/TermsOfService"
import PrivacyPolicy from "./pages/policies/PrivacyPolicy"

//Documentation
import { FrontPage, Contents as Frontfurther } from "./pages/docs/FrontPage"
import Blank from "./pages/docs/Blank"
import Solution from "./pages/docs/Solution"
import Ohno from "./pages/docs/Ohno"
import DKubernetes from "./pages/docs/Kubernetes"
import DIotsmartcitylayer from "./pages/docs/Iotsmartcitylayer"
import DAccesscontrollayer from "./pages/docs/Accesscontrollayer"
import DConnectors from "./pages/docs/Connectors"
import DSecurity from "./pages/docs/Security"
import DAnalytics from "./pages/docs/Analytics"
import DRoadmap from "./pages/docs/Roadmap"
import DInfrastructureascode from "./pages/docs/Infrastructureascode"

// Landing


// Dashboard components
const SaaS = async(() => import("./pages/dashboards/SaaS"));
const Landing = async(() => import("./pages/presentation/Landing"));

const routes = [
  {
    path: "/",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <SaaS />,
      },
    ],
  },

  {
    path: "sales",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Sales />
      }
    ]
  },
  {
    path: "tools",
    element: <DashboardLayout />,
    children: [
      {
        path: "kubernetes",
        element: <Kubernetes />,
      },
      {
        path: "smartcity",
        element: <SmartCity />,
      },
      {
        path: "accesscontrol",
        element: <AccessControl />,
      },
      {
        path: "connectors",
        element: <Connectors />,
      },
      {
        path: "security",
        element: <Security />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "verify",
        element: <Verify />
      }
    ],
  },
  {
    path: "documentation",
    element: <DocLayout />,
    children: [
      {
        path: "",
        element: <FrontPage />,
      },
      {
        path: "welcome",
        element: <FrontPage />,
      },
      {
        path: "further",
        element: <Frontfurther />,
      },
      {
        path: "kubernetes",
        element: <DKubernetes />
      },
      {
        path: "iotsmartcitylayer",
        element: <DIotsmartcitylayer />
      },
      {
        path: "accesscontrollayer",
        element: <DAccesscontrollayer />
      },
      {
        path: "connectors",
        element: <DConnectors />
      },
      {
        path: "security",
        element: <DSecurity />
      },
      {
        path: "analytics",
        element: <DAnalytics />
      },
      {
        path: "roadmap",
        element: <DRoadmap />
      },
      {
        path: "infrastructureascode",
        element: <DInfrastructureascode />
      },
      {
        path: "solution",
        element: <Solution />
      }
    ],
  },

  {
    path: "TermsOfService",
    element: <DocLayout />,
    children: [
      {
        path: "",
        element: <TermsOfService />,
      },
    ],
  },
  {
    path: "PrivacyPolicy",
    element: <DocLayout />,
    children: [
      {
        path: "",
        element: <PrivacyPolicy />,
      },
    ],
  },

  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
