import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography, Link } from "@mui/material";
import GuestGuard from '../../components/guards/GuestGuard'
import { ReactComponent as Logo } from "../../vendor/profirator-logo-black.svg";
import SignUpComponent from "../../components/auth/SignUp";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.logo};
  width: 256px;
  height: 124px;
  margin-bottom: 32px;
`;
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUp() {

  return (
    
    <GuestGuard>
      <React.Fragment>
        <Brand />
        <Wrapper>
          <Helmet title="Sign Up" />
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Create new user
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Please fill the form below
          </Typography>
          <SignUpComponent />
          <Typography>User already created? {<Link href="sign-in">Log in</Link>}</Typography>
        </Wrapper>
      </React.Fragment>
    </GuestGuard>
  );
}
  

export default SignUp;
