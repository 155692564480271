import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);


const Typography = styled(MuiTypography)(spacing);

const PrivacyPolicy = () => {
  return (
    <>
      
      <p align="right">
        Last updated: May 2022
      </p>
      <p>
        We know that providing personal information is an act of trust and we take
        that seriously.
      </p>
      <p>
        When you visit our website or use our mobile app, this Privacy Notice
        should help you work out what we collect your data for and how we protect
        it for you. If you have any questions or would like to make any requests
        about your personal information such as opting out of marketing, please
        email lakes@profirator.fi
      </p>
      <p>
        We go into more detail further on this page, but here's as summary:
      </p>
      <p>
        It is important that you read this Privacy Notice together with any other
        Privacy Notice we may provide on specific occasions when we are collecting
        or processing personal information about you so that you are fully aware of
        how and why we are using your data.
      </p>
      <p>
        It should also be read in conjunction with any Terms and Conditions on our
        website and will form part of these.
      </p>
      <p>
        This website may include links to third-party websites, plug-ins, and
        applications. Clicking on those links or enabling those connections may
        allow third parties to collect or share data about you. We do not control
        these third-party websites and are not responsible for their privacy
        notices. When you leave our website, we encourage you to read the Privacy
        Notice of every website you visit.
      </p>
      <h1>
        1. Glossary of Terms
      </h1>
      <p>
        Personal Data means any information relating to an identified or
        identifiable natural person (data subject); an identifiable natural
        person is one who can be identified, directly or indirectly, in particular
        by reference to an identifier such as a name, an identification number,
        location data, an online identifier or to one or more factors specific to
        the physical, physiological, genetic, mental, economic, cultural or social
        identity of that natural person;
      </p>
      <p>
        Operational Data means any data that the User provides to the Platform
        through an API endpoint;
      </p>
      <p>
        Data Controller is the main decision-makers  they exercise overall
        control over the purposes and means of the processing of personal data.
      </p>
      <p>
        EEA means the European Economic Area or all the countries which are party
        to the Agreement on the European Economic Area.
      </p>
      <p>
        Applicable Data Protection Laws means the General Data Protection
        Regulation (EU) 2016/679 of the European Parliament and Council of 27    <sup>th</sup> April 2016 on the protection of personal data and on the free
        movement of such data, and any other data protection rules and laws which
        may be applicable from time to time.
      </p>
      <p>
        Legitimate Interest means the interest of our business in conducting and
        managing our business to enable us to give you the best service / product
        and the best and most secure experience. We make sure we consider and
        balance any potential impact on you (both positive and negative) and your
        rights before we process your personal data for our legitimate interests.
      </p>
      <p>
        Performance of Contract means processing your data where it is necessary
        for the performance of a contract to which you are a party or to take steps
        at your request before entering into such a contract.
      </p>
      <h1>
        2. Who we are
      </h1>
      <Typography variant="subtitle2">
        2.1. About the Data Controller
      </Typography>
      <p>
        lakes.profi.space or lakes (hereinafter referred to as the Platform) is
        an online platform based in Finland. We are a limited liability company
        established in terms of Finnish law. For the purposes of the application
        data protection law, the Platform is the Data Controller of the data you
        provide to us.
      </p>
      <Typography variant="subtitle2">
        2.2. About the Service
      </Typography>
      <Typography variant="subtitle2">
        2.3. Platformoffers self-service data and Internet of Things (IoT)
        platform services hosted by Profirator Oy, where users can connect the data
        sources like IoT and other internet services and visualise various
        dashboards and track the critical data points (hereinafter referred to as
        the Service). Users can use the platform for a single data source or a
        full-fledged IoT platform where it is possible to open Application
        Programming Interfaces (APIs) for developers for the data collected in
        the platform. Platform is based on NGSIv2 data and API standards.
      </Typography>
      <Typography variant="subtitle2">
        2.4. The data collected from the data sources in connection with the
        Service does not include personal data as defined by Applicable Data
        Protection Laws, unless the data you input from such data sources includes
        such. The Platform does not process any personal data that you input in
        connection with the Service, and the only personal data that is collected
        is as stipulated in Section 3of this document.
      </Typography>
      <Typography variant="subtitle2">
        2.5. Contact Details
      </Typography>
      <p>
        You may contact us by email on lakes@profirator.fi
      </p>
      <h1>
        3. About your data
      </h1>
      <Typography variant="subtitle2">
        3.1. Data we process
      </Typography>
      <p>
        The nature of the Platforms service is to host Users data, including
        videographic and visual data. This data is input through an endpoint the
        Platform provides. Kindly note that the Platform only hosts and stores the
        information that the User provides and does not process or refine any
        Operational Data that the User inputs, such data points. The User is
        strictly prohibited from inputting Personal Data when uploading Operational
        Data. The User is also strictly prohibited from uploading Operational Data
        which includes information which is of a private and/or intimate nature
        (such as video streaming of a bedroom), or which does not belong to them
        (such as video-streaming of a neighbours yard). Kindly note that we do
        form a historical database from the data User provides for your convenience
        and note that the data User provides is accessible and modifiable by all
        Users.
      </p>
      <p>
        The following is a list of the types of personal data we collect/process in
        your regard:
      </p>
      <table border="1" cellSpacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td width="151" valign="top">
              <p align="left">
                <strong>Data Category</strong>
              </p>
            </td>
            <td width="189" valign="top">
              <p align="left">
                <strong>Type of Personal Data</strong>
              </p>
            </td>
            <td width="123" valign="top">
              <p align="left">
                <strong>Purpose</strong>
              </p>
            </td>
            <td width="138" valign="top">
              <p align="left">
                <strong>Our legal basis for processing</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="151" valign="top">
              <p align="left">
                <strong>Registration Data</strong>
              </p>
              <p align="left">
                Data provided to us when you register an account on the
                Platform
              </p>
            </td>
            <td width="189" valign="top">
              <p>
                Name and surname,
              </p>
              <p>
                email address, IP address, encrypted password,
              </p>
            </td>
            <td width="123" valign="top">
              <p align="left">
                To register an applicant on the Platform and to ensure that
                we have all information necessary, to provide you with the
                best service possible
              </p>
            </td>
            <td width="138" valign="top">
              <p align="left">
                Legitimate interest / Contractual performance
              </p>
            </td>
          </tr>
          <tr>
            <td width="151" valign="top">
              <p align="left">
                <strong>Account History</strong>
              </p>
              <p align="left">
                All Data relating to your purchasing activity and patterns
              </p>
            </td>
            <td width="189" valign="top">
              <p align="left">
                Purchase history
              </p>
            </td>
            <td width="123" valign="top">
              <p align="left">
                To process your transactions for any fees / expenses. For
                accounting and taxation purposes
              </p>
            </td>
            <td width="138" valign="top">
              <p align="left">
                Contractual performance. Legal obligation
              </p>
            </td>
          </tr>
          <tr>
            <td width="151" valign="top">
              <p align="left">
                <strong>Subscription Data</strong>
              </p>
              <p align="left">
                Information that you provide to us for the purpose of
                subscribing to our email notifications and / or newsletters
              </p>
            </td>
            <td width="189" valign="top">
              <p align="left">
                Name and surname, email address, IP address
              </p>
            </td>
            <td width="123" valign="top">
              <p align="left">
                To send you information about our products, services and
                news
              </p>
            </td>
            <td width="138" valign="top">
              <p align="left">
                Consent
              </p>
              <p>
                NOTE that you have the right to withdraw your consent, at
                any time, by sending email to
                <a href="mailto:lakes@profirator.fi">
                  lakes@profirator.fi
                </a>
                with the topic UNSUBSCRIBE.
              </p>
            </td>
          </tr>
          <tr>
            <td width="151" valign="top">
              <p align="left">
                <strong>Communications Data</strong>
              </p>
              <p align="left">
                Data provided to us when you send us an email to request
                information or when you complete a Contact Us form                    <strong></strong>
              </p>
            </td>
            <td width="189" valign="top">
              <p align="left">
                Name and surname, email address, IP address, and other
                information you may enter into the email or form.
              </p>
            </td>
            <td width="123" valign="top">
              <p align="left">
                To reply to your queries about our services
              </p>
            </td>
            <td width="138" valign="top">
              <p align="left">
                Legitimate interest
              </p>
            </td>
          </tr>
          <tr>
            <td width="151" valign="top">
              <p align="left">
                <strong>User Activity</strong>
              </p>
              <p align="left">
                Information relating to app usage and user experience and
                analytics
              </p>
            </td>
            <td width="189" valign="top">
              <p align="left">
                User activity on the Platforms services.
              </p>
            </td>
            <td width="123" valign="top">
              <p align="left">
                To provide and improve services.
              </p>
            </td>
            <td width="138" valign="top">
              <p align="left">
                Legitimate interests.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <Typography variant="subtitle2">
        3.2. Who do we share data with?
      </Typography>
      <p>
        We will not share any of your personal data with third parties without your
        permission, except where this is strictly required for us to provide you
        with a service you have engaged us to provide or where we are obliged to do
        so in terms of our legal or regulatory obligations.
      </p>
      <p>
        If we ever have to share your personal data with an entity outside the EU,
        we will do so in accordance with the requirements of the GDPR and any other
        applicable law.
      </p>
      <p>
        Where required, we will use your information to evaluate or conduct a
        merger, divestiture, restructuring, reorganisation, dissolution, or other
        sale or transfer of some or all of our assets, whether as a going concern
        or as part of bankruptcy, liquidation, or similar proceeding, in which
        personal data held by us about our Service users is among the assets
        transferred.
      </p>
      <Typography variant="subtitle2">
        3.3. Marketing
      </Typography>
      <p>
        We use your information for customer insight and marketing purposes. In
        some cases, we will purchase information from third-parties, but this
        usually aggregated data and does not normally directly identify you.
      </p>
      <p>
        We'll only send you marketing emails or contact you about our products or
        our related services if you've agreed to this. This may be in the form of
        email, post, SMS, telephone, or targeted online advertisements.
      </p>
      <p>
        You may unsubscribe from our marketing communications by sending us an
        email at <a href="mailto:lakes@profirator.fi">lakes@profirator.fi</a> with
        the topic UNSUBSCRIBE.
      </p>
      <Typography variant="subtitle2">
        3.4. Retention of Data
      </Typography>
      <p>
        We keep your personal data no longer than strictly necessary for the
        following purposes:
      </p>
      <p>
         To fulfil the purpose for which your personal data was originally
        collected;
      </p>
      <p>
         As required by a specific law which we may be subject to;
      </p>
      <p>
         To manage any legal claims in relation to which such data may be required
        as evidence.
      </p>
      <h1>
        4. Storage and security of Information
      </h1>
      <p>
        We store the information you provide to us and the information we collect
        electronically in the cloud and computer databases. We own, maintain, and
        operate our computer servers containing our databases. Access to our
        computer servers is controlled by a series of security features.
      </p>
      <p>
        The Platform is committed to keeping your personal data safe and secure.
      </p>
      <p>
        Some of our security measures include:
      </p>
      <p>
        <strong>Technical Security</strong>
      </p>
      <p>
         Infrastructure only accessible through VPN with restricted access
        controls applied
      </p>
      <p>
         Portal access management restricted to specific users only
      </p>
      <p>
         Only http/https port are whitelisted on web servers to the internet
      </p>
      <ul>
        <li>
          We enforce HTTPS on websites
        </li>
      </ul>
      <p>
         Managed identity database access on native services
      </p>
      <ul>
        <li>
          Encypted passwords
        </li>
      </ul>
      <p>
        <strong>Organisational Security</strong>
      </p>
      <p>
         Access to information is limited to a need-to-know basis.
      </p>
      <p>
         All employees have to undergo an Information Security training
      </p>
      <p>
         NDA's in-place for partnerships and code access
      </p>
      <p>
         Compliance measures for data processors
      </p>
      <p>
        However, whilst appropriate measures are in place, we cannot guarantee that
        our security measures will not prevent a third party from unlawfully
        accessing or intercepting personal information stored or where an error may
        occur in the administration of our website. In consideration of which we
        advise that you use caution whenever submitting personal information
        online.
      </p>
      <Typography variant="subtitle2">
        4.1. What you can do to help protect your data
      </Typography>
      <p>
        We do not store financial data. We will never ask you to confirm any bank
        account or credit card details via email. If you receive an email claiming
        to be from the Platform asking you to do so, please ignore it and do not
        respond.
      </p>
      <p>
        If you are using a computing device in a public location, we recommend that
        you always log out and close the website browser when you complete an
        online session. In addition, we recommend that you take the following
        security measures to enhance your online safety both in relation to the
        Platform and more generally:
      </p>
      <p>
         Keep your account passwords private.
      </p>
      <p>
         Do not use the same password for multiple accounts. If your password
        becomes known, it may be used to access your other accounts.
      </p>
      <p>
         Never use your account password for an account that contains sensitive
        data.
      </p>
      <p>
         When creating a password, use at least 8 characters. A combination of
        letters and numbers is best. Do not use dictionary words, your name, email
        address, or other personal data that can be easily obtained. We also
        recommend that you frequently change your password.
      </p>
      <h1>
        5. Your Data Protection Rights
      </h1>
      <p>
        At any point in time during our processing of your data, you have the
        following rights. All requests in this regard may be made by sending an
        email to: <a href="mailto:lakes@profirator.fi">lakes@profirator.fi</a> We
        will also forward your request to relevant third parties mentioned above as
        required.
      </p>
      <p>
         Right of access  You can request a copy of the information that we hold
        about you.
      </p>
      <p>
         Right of rectification  You can ask us to correct data that we hold
        about you if it is inaccurate or incomplete.
      </p>
      <p>
         Right to be forgotten  in some situations, you may ask us to delete
        certain data we hold about you and we will always comply to the extent
        allowed or required by any Applicable Data Protection Laws.
      </p>
      <p>
         Right to restriction of processing  in some situations, you may ask us
        to restrict the processing of your data.
      </p>
      <p>
         Right of portability  You may ask us to transfer certain data we hold
        about you to another organisation.
      </p>
      <p>
         Right to object  You have the right to object to certain types of
        processing, such as direct marketing.
      </p>
      <p>
         Right to object to automated processing, including profiling  You also
        have the right to object to the legal effects of automated processing or
        profiling.
      </p>
      <p>
         Right to complain about how your Personal Data is being processed by us
        (or third parties), or about how your complaint has been handled  You can
        lodge a complaint directly with the Office of the Data Protection Ombudsman
        (tietosuoja@om.fi) and with us (lakes@profirator.fi).
      </p>
      <h1>
        6. Cookies and other tracking mechanisms
      </h1>
      <p>
        Like most online platforms, the Platform uses cookies to collect
        information. Cookies are small data files of letters and numbers that are
        stored in your browser or the hard drive of your computer and contain
        information that is transferred to your computers hard drive. We use
        cookies on our website to improve its functionality and to allow us to
        constantly improve the website. When you continue to browse this website,
        you are agreeing to our use of cookies.
      </p>
      <p>
        We use cookies as follows:
      </p>
      <table border="1" cellSpacing="0" cellpadding="0" width="622">
        <tbody>
          <tr>
            <td width="172" valign="top">
              <p>
                <strong>TYPE OF COOKIE</strong>
              </p>
            </td>
            <td width="99" valign="bottom">
              <p>
                <strong>NAME</strong>
              </p>
            </td>
            <td width="352" valign="bottom">
              <p>
                <strong>FUNCTION</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="172" valign="top">
              <p align="left">
                <strong> Temporary cookies</strong>
              </p>
            </td>
            <td width="99" valign="top">
              <p align="left">
                <strong>Stored cookie</strong>
              </p>
            </td>
            <td width="352" valign="top">
              <p align="left">
                These cookies are used to remember your login session, so
                you won't need to re-enter them every time you use a site.
                This cookie is mandatory. Cookie will expire after token
                expiration period.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <Typography variant="subtitle2">
        6.1. How to control cookies
      </Typography>
      <p>
        To find out more about cookies, including how to see what cookies have been
        set and how to manage and delete them, visit www.aboutcookies.org or    <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.
      </p>
      <p>
        Most web browsers today allow you to control cookies through the settings
        and preferences of your browser of choice. It is possible for you to
        disable cookies or set your browser to alert you when cookies are being
        sent. The website supports your right to block any unwanted Internet
        activity, especially from unscrupulous websites. You can delete all cookies
        that are already on your computer, and you can set your browser to prevent
        them from being placed. If you do this, however, you may have to manually
        adjust some preferences every time you visit a site, and some services and
        functionalities may not work.
      </p>
      <p>
        Keep in mind, however, that blocking all cookies or even some of them will
        have a negative impact on the usability and rendering of many websites,
        including our website. If you block cookies, you will not be able to use
        all the features on the website and might not be able to view it correctly
        in the way it was originally intended to be displayed.
      </p>
      <Typography variant="subtitle2">
        6.2. Changes to this Privacy and Cookies Notice
      </Typography>
      <p>
        We may update this Policy from time to time. If we make significant
        changes, we will let you know but please regularly check this Policy to
        ensure you are aware of the most updated version.
      </p>
      <h1>
      </h1>
    </>

  )
}

function Welcome() {
  return (
    <React.Fragment>
      <Helmet title="Getting Started" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Privacy Policy
          </Typography>


          <Divider my={6} />

          <PrivacyPolicy />


        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Welcome;
