import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import Code from "../../components/Code";
import YoutubeEmbed from "../../components/youtube/YoutubeEmbed";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

const GrafanaChart = ({ link, minWidth, minHeight }) => {
  return (
    <Grid item xs={6} sm={12} md={6} lg={4} style={{
      minWidth: minWidth,
      minHeight: minHeight,
      width: minWidth,
      height: minHeight,
      margin: 0,
      padding: 0,
    }}>
      <iframe title="grafana" src={link} frameBorder="0" width="100%" height="100%"></iframe>
    </Grid>
  )
}


function Contents() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Explore further
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        Here is a detailed description of the system architecture and main
        components used. It’s divided in six sections:
      </Typography>
      <ol>
        <li>Kubernetes</li>
        <li>IoT Smart city layer</li>
        <li>Access control layer</li>
        <li>Connectors</li>
        <li>Security</li>
        <li>Analytics</li>
      </ol>
      <Typography variant="h3" gutterBottom>
        Kubernetes
      </Typography>
      <Typography variant="body1">
        You may not know it, but this system is running on Kubernetes. Why? Because:
      </Typography>

      <ul>
        <Typography variant="body1">
          <li>
            High availability
          </li><li>
            Scalability
          </li><li>
            Declarative configurations instead of imperative static rules.
          </li><li>
            Everybody likes it. Industry standard.
          </li>
        </Typography>
      </ul>
      <br />
      Read in more detail <Link component={NavLink} to="/documentation/kubernetes"> here. </Link>


      <Typography variant="h3" gutterBottom my={4}>
        IoT Smart city layer
      </Typography>
      <Typography variant="body1">
        We built our stuff by mainly using other people’s components. This way we harness the massive R&D of others very few organizations can match. We mainly use FIWARE components, and we are an active FIWARE ecosystem player.
        <br />
        Read in more detail <Link component={NavLink} to="/documentation/iotsmartcitylayer"> here. </Link>
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        Access control layer
      </Typography>
      <Typography variant="body1">
        Controlling access and guaranteeing data sovereignty in data spaces or lakes
        is one of our main priorities. We have spent years on this and we keep on pushing
        the envelope.
        <br />
        Read in more detail <Link component={NavLink} to="/documentation/accesscontrollayer"> here. </Link>
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        Connectors
      </Typography>
      <Typography variant="body1">
        We expose the NGSI-V2 interface for data access. In most cases, your data source
        does not talk NGSI-V2. Over the years, we have develop a stack of connectors,
        including: Sigfox, LoraWAN, mqtt, CVS converters, email adapters, and other
        proprietary-to-NGSI connectors.
        <br />Read in more detail <Link component={NavLink} to="/documentation/connectors"> here. </Link>
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        Security
      </Typography>
      <Typography variant="body1">
        This is one of the fundamentals. We do security first. Our data centers are
        located in Finland and the data stays here. Your data is safe with us.
        <br />Read in more detail <Link component={NavLink} to="/documentation/security"> here. </Link>
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        Analytics
      </Typography>
      <Typography variant="body1">
        You have the data, but what to do with it? During the years we have processed
        a staggering amount of different data. Our experts have written a piece on how
        we approach this.
        <br />Read in more detail <Link component={NavLink} to="/documentation/analytics"> here. </Link>
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        Feedback
      </Typography>
      <Typography variant="body1">
        We need it! We cannot develop our system to right direction without your help.
        Any question, suggestion or other bother, connect to our chat and let us know.
        Alternatively, create an issue to our Gitlab, this is where our Roadmap lives and
        where the development is tracked.
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        I Want this system
      </Typography>
      <Typography variant="body1">
        If you are tendering for a solution check out this page.
        It outlines the technical requirements and will help you in your preparations.
        Have enough cash in the pocket for direct purchase? Contact sales.
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        I Want this system but with(out) xyz
      </Typography>
      <Typography variant="body1">
        Contact sales. We can do (as you can see from our references page) pretty much what
        ever you need. Your wish is our command.
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        Your system is stupid and <code>{'<HYPERSCALER NAME>'}</code> is way better
      </Typography>
      <Typography variant="body1">
        That may be. There is no way of providing a system that meets everyone’s every requirement
        or constraint. In any case, if you are open for discussion read this: “why hyperscaler
        solution may not be for me”.
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        Roadmap
      </Typography>
      <Typography variant="body1">
        At this time, our PaaS is pretty basic, but we want to generate value to you immediately.
        Things keep on getting better, so check out the roadmap and join the discussion to
        influence it.
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        Infrastructure as code
      </Typography>
      <Typography variant="body1">
        We are also a GitLab partner. Thus Infrastructure as code is natural to us.
        <br />Read in more detail <Link component={NavLink} to="/documentation/infrastructureascode"> here. </Link>
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        FAQ
      </Typography>
      <Typography variant="body1">
        You ask, we answer. Here are some of the most common questions and answers to those.
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        Support for PaaS
      </Typography>
      <Typography variant="body1">
        We support business hours and with best effort. Create an issue, join the forum or chat.
      </Typography>
      <Typography variant="h3" gutterBottom my={4}>
        References
      </Typography>
      <Typography variant="body1">
        This is not our first rodeo. Check out the references and get inspired!
      </Typography>
    </Box>
  );
}

function WelcomeStart() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Welcome to Lakes!
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        <GrafanaChart
          link="https://charts.profi.space/d-solo/IV3BnvC7k/tampere-weather?orgId=2&from=1655095964055&to=1655117564055&panelId=7"
          minWidth="100%"
          minHeight="25vh"
          maxHeight="50vh"
        >
        </GrafanaChart><br />
        <Typography variant="h3" gutterBottom>
        Getting started
        </Typography>

        <br />The chart you see above is real data flowing into the system. I’m sure you want to get right started on testing. Here is a TL;DR:<br /> <br />Getting data is simple,  you just need a valid Bearer token. For getting the 
        Bearer token, use the Curl command below. Change the text EMAIL to the email address you gave when signin-up in https://lakes.profi.space/auth/sign-up. Change the text PASSWORD to the password you gave when signing-up and execute the curl command.
        <Code>curl --location --request POST 'token.profi.space/oauth2/token' --header 'Content-Type: application/x-www-form-urlencoded' --data-urlencode 'grant_type=password' --data-urlencode 'username=EMAIL' --data-urlencode 'password=PASSWORD'</Code> 
        You should receive a response that looks like this:
        <Code>"access_token":"e16c2e924970bb6812cd7cc5fce9f3f9bc63ab58","token_type":"bearer","expires_in":3599,"refresh_token":"744b2326dc40a92cc0ef682531ff8b12fa82f535","scope":["bearer"]</Code>
        Next we'll create the Curl command needed to get some data by using the Curl command below. Change the text TOKENHERE with "access_token" value and execute the curl command.
        <Code>curl --location --request GET 'https://context.profi.space/v2/entities' --header 'Authorization: Bearer TOKENHERE'</Code>
        You should receive JSON response with some data similar to this:
        <Code>{`{
        "id": "urn:ngsi-ld:WeatherObserved:owmtampere-FI-Tampere-634963",
        "type": "WeatherObserved",
        "@context": {
        "type": "StructuredValue",
        "value": [
        "https://smartdatamodels.org/context.jsonld",
        "https://uri.etsi.org/ngsi-ld/v1/ngsi-ld-core-context.jsonld"
        },
        "atmosphericPressure": {
        "type": "Number",
        "value": 1009,
        "metadata": {}
        }`}</Code>
        <br /> It may vary, depending what data has been fed to the system.
        <br /> That's it! If you are unsure, visit https://lakes-support-chat.profirator.fi/channel/general for support or check out the Youtube video below.
        <br /> Happy Lakes journey! 

      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        <YoutubeEmbed embedId="8i7a6PdNl9M"/>
        <br/>
        We also have other videos on our Youtube channel, so check them out. They cover other aspects of Lakes and few Smart city concepts.
      </Typography>
      <Typography variant="h3" gutterBottom>
        Pushing Data
      </Typography>

      <Typography variant="subtitle1" gutterBottom my={4}>
        Now that the basics are covered, let's push some data:
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        You need to get a bearer token. That is explained in the video above. 
        <ol>
          <li>Get a Bearer token</li>
          <li>Execute this curl with the token:</li>
          <Code>{`
	  curl --location --request POST 'https://context.profi.space/v2/entities?options=keyValues' --header 'Authorization: Bearer INSERTTOKENHERE' \
	  --header 'Content-Type: application/json' \
	  --data-raw '{
	    "id": "test",
	    "type": "test",
	    "value": 1
	  }'
          `}</Code>
        </ol>
        ..and that’s it! Now you can query the data by executing the following curl with a valid Bearer token, there is a token here, but it has expired long ago :) :
        <Code>{`
        curl --location --request GET 'https://context.profi.space/v2/entities/test' --header 'Authorization: Bearer 212e1f7e50fd52c8dd1bc83ab50fd28596a0acc0'
        `}</Code>
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        If you are familiar with Postman, use this postman collection to make your life easier. The Curl commands shown here are using the NGSI-V2 ETSI standard API. Check out the <a href="https://fiware.github.io/specifications/ngsiv2/stable/">API specification</a> it's quite rich and has powerful features.
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        Please take into consideration:
        <br />
        Pushing data to the system has some restrictions, since we need to protect ourselves
        from exploitation and make sure everyone has a nice user experience. In addition few
        things to understand (these are also in our ToS):
        <ol>
          <li>
            Your data can be accessed by anyone. This is for evaluation only. Do not push any data
            with Personally identifiable or other sensitive information.
          </li><li>
            Traffic is throttled. This is so that we can predict the correct amount of capacity, let us
            be frugal and not vaste resources (less Co2).
          </li><li>
            Your data may disappear or change. We have disabled the flat out DELETE operations, but
            there are other means on manipulating the data.
          </li>
        </ol>
        Don’t like this? Contact our sales (ilari.mikkonen(at)profirator.fi) and we get you your own piece of paradise.
        <br />
        <br />
        Finally, if you need any <b>help</b>, register to our <a href="https://lakes-support-chat.profirator.fi/channel/general">chat</a> where you can ask questions and connect with others swimming in the lakes.
        <br />
      </Typography>
    </Box>
  );
}

function FrontPage() {
  return (
    <React.Fragment>
      <Helmet title="Lakes - Profirator IoT Smart City Platform as Service" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Lakes - Profirator IoT Smart City Platform as Service
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/documentation/welcome">
              Documentation
            </Link>
            <Typography>Getting Started</Typography>
          </Breadcrumbs>

          <Divider my={6} />

          <WelcomeStart />
          <Contents />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export { FrontPage, Contents };
