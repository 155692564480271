import {
  Monitor,
} from "react-feather";

const pagesSection = [
  {
    icon: Monitor,
    href: "/dashboard/default",
    title: "Default",
  }
];
/*
const userData = [
  {
    href: "/PrivacyPolicy",
    title: "Privacy Policy",
  },
  {
    href: "/auth/sign-up",
    title: "Sign up",
  },
  {
    href: "/auth/sign-in",
    title: "Login",
  },
  {
    href: "/TermsOfService",
    title: "Terms of Service",
  }
]
*/
const documentation = [
  {
    href: "/documentation",
    title: "Documents",
  },
]
const contact = [
  {
    href: "/sales",
    title: "Contact sales",
  },
]
const toolsSection = [
  {
    href: "/tools/kubernetes",
    title: "Kubernetes"
  },
  {
    href: "/tools/smartcity",
    title: "IoT Smart City"
  },
  {
    href: "/tools/accesscontrol",
    title: "Access Control"
  },
  {
    href: "/tools/connectors",
    title: "Connectors"
  },
  {
    href: "/tools/security",
    title: "Security"
  },
  {
    href: "/tools/analytics",
    title: "Analytics"
  }
]
const navItems = [
  {
    title: "Dashboards",
    pages: pagesSection,
  },
  {
    title: "Tools",
    pages: toolsSection
  },
  {
    title: "User Guide",
    pages: documentation
  },
  {
    title: "Contact details",
    pages: contact
  }
];

export default navItems;
