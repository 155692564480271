import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import "./i18n";
import createTheme from "./theme";
import routes from "./routes";
import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import { AuthProvider } from "./contexts/TokenContext";
import reportUsage from "./utils/reportUsage";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
require('dotenv').config()

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();
  useEffect(() => {

    reportUsage({
      "actor": {
        "type": "User"
      },
      "action": {
        "type": "PageLoad"
      },
      "context": {
        "type": "Arrive",
        "name": "FrontPage"
      }
    });

    return () => {
      console.log("Cleanup after a PageLoad");
    };
  }, []);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Profirator Lakes"
        defaultTitle="Profirator Lakes"
      />
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Provider store={store}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>{content}</AuthProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
      </Provider>
    </HelmetProvider>

  );
}

export default App;
