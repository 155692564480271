import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Card = styled(MuiCard)(spacing);


const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);


const Typography = styled(MuiTypography)(spacing);


const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const BusinessCard = styled(Card)` 
  display: block;
  height: "45vw";
`;

const Spacer = styled.div(spacing);


function Details() {
  return (
    <BusinessCard>
      <CardContent>
        <Grid container>
          
          <Grid item xs={6}>
            <Avatar alt="Ilari Mikkonen" src="/static/img/avatars/ilari.jpg" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" component="div" gutterBottom>
              <Box fontWeight="fontWeightMedium">Ilari Mikkonen</Box>
              <Box fontWeight="fontWeightRegular">Product Owner</Box>
            </Typography>

            <Spacer mb={12}/>

            <Typography>
              <Box fontWeight="fontWeightRegular">Phone +358 44 712 6866 </Box>
            </Typography>
            <Typography>
              <Box fontWeight="fontWeightRegular">ilari.mikkonen@profirator.fi</Box>
            </Typography>

            
          </Grid>
          
        </Grid>
      </CardContent>
    </BusinessCard>
  );
}


function Profile() {
  return (
    <React.Fragment>
      <Helmet title="Profile" />

      <Typography variant="h3" gutterBottom display="inline">
        Contact Sales
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
       
        <Typography>Contact Sales</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}>
          <Details />
        
        </Grid>
      
      </Grid>
    </React.Fragment>
  );
}

export default Profile;
