import { createContext, useEffect, useReducer } from "react";
import { setSession } from "../utils/token";
//import axios from "../utils/axios";
import axios from "axios"
const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const TokenReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(TokenReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const tokenExists = document.cookie.split('; ').filter(row => row.startsWith('tokenExists=')).map(c => c.split('=')[1])[0] //backend has the token httpOnly


        if (tokenExists) {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/keyrock/sign-in`,
            {
              withCredentials: true, //cookies
            });

          const { user } = response.data;
          setSession(user);

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });

        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {

    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/keyrock/sign-in`,
      {
        email,
        password,
      },
      {
        withCredentials: true, //cookies
      });


    const { user } = response.data;

    if (user) setSession(user);

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signOut = async () => {
    const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/keyrock/sign-out`, { withCredentials: true })
    if (response.status === 204) {
      setSession(null);
      dispatch({ type: SIGN_OUT });
    }
  };

  const signUp = async (email, password, firstName, lastName) => {


    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/keyrock/sign-up`, {
      email,
      password,
      firstName,
      lastName,
    })

    const { user } = response.data;

    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });


  };

  const resetPassword = async (email) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/keyrock/reset`, {
      email
    });
    return response.status
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "code",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
