import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import Code from "../../components/Code";
import YoutubeEmbed from "../../components/youtube/YoutubeEmbed";


const GrafanaChart = ({ link, minWidth, minHeight }) => {
  return (
    <Grid item xs={6} sm={12} md={6} lg={4} style={{
      minWidth: minWidth,
      minHeight: minHeight,
      width: minWidth,
      height: minHeight,
      margin: 0,
      padding: 0,
    }}>
      <iframe title="grafana" src={link} frameBorder="0" width="100%" height="100%"></iframe>
    </Grid>
  )
}



const BiggerTypography = styled(MuiTypography)`
  font: 1em
`
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);


const Example1 = () => {
  return (
    <Box mb={10}>
      <Typography variant="h4">1. Creating a list (h4) </Typography>
      <Typography variant="body1">
        Testing (body1)
        <ol>
          <li>Kubernetes</li>
          <li>IoT Smart city layer</li>
          <li>Access control layer</li>
          <li>Connectors</li>
          <li>Security</li>
          <li>Analytics</li>
        </ol>
      </Typography>
    </Box>
  )
}

const Example2 = () => {
  return (
    <Box mb={10}>
      <Typography variant="h4">2. Code component </Typography>

      <Typography variant="subtitle1">Single line </Typography>
      <Code gutterBottom>
        This stuff looks weird as it is inside a component. It does not need any typography just the &lt;Code&gt; &lt;/Code&gt; -tag.
      </Code>

      <Typography variant="subtitle1">Multiline </Typography>
      <Code>{
        `
        This is how to make the code multirow.
        Not the cleanest but it works.
        works by adding {\` your text multi-line here \`}
        `
      }
      </Code>


    </Box>
  )
}


const Example3 = () => {
  return (
    <Box mb={10}>
      <Typography variant="h4" gutterBottom>3. Grafana chart </Typography>
      <Typography variant="subtitle1">Grafana chart is a custom made component. Adjust its minHeight and maxHeight to scale it </Typography>

      <GrafanaChart
        link="https://charts.profi.space/d-solo/IV3BnvC7k/tampere-weather?orgId=2&from=1655095964055&to=1655117564055&panelId=7"
        minWidth="100%"
        minHeight="40vh"
        maxHeight="50vh"
      >
      </GrafanaChart>
    </Box>
  )
}


const Example4 = () => {
  return (
    <Box mb={10}>
      <Typography variant="h4" gutterBottom>4. Youtube links </Typography>

      <Typography variant="subtitle1" gutterBottom>
        Youtube embed works with its own component:
      </Typography>
      <YoutubeEmbed embedId="8i7a6PdNl9M" />
    </Box>
  )
}

const Conclusion = () => {
  return (
    <Box mb={10}>
      <Typography variant="h4" gutterBottom>Conclusion </Typography>

      <Typography variant="subtitle1" gutterBottom>
        Please keep the components the right size for easier readability.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        For example Contents should have the main thing, and the default exported e.g. "TypographyExamples" in this case should have easy to understand order.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        A new paragraph should be with a new &lt;Typography&gt; tag. Long texts with &lt;br&gt; is sometimes hard to read.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        The variant names are only to ease the developers work. Feel free to use subtitle1 or body1, but keep it consistent. Gutterbottom adds a small margin after the text
      </Typography>


    </Box>
  )
}

function Contents() {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Here are four examples how to do things. This is also a example how to build stuff in pieces.
      </Typography>
      <Divider />
      <Example1 />
      <Example2 />
      <Example3 />
      <Example4 />
      <Conclusion />
    </>
  );
}

function TypographyExamples() {
  return (
    <Box mb={10}>
      <Typography variant="h1" gutterBottom>
        Big header h1
      </Typography>

      <Typography variant="h2" gutterBottom>
        Bit smaller header Header 2
      </Typography>
      <Typography variant="h3" gutterBottom>
        Bit smaller header
      </Typography>

      <Typography variant="h4" gutterBottom>
        h4, but it has not been defined
      </Typography>

      <Typography variant="h5" gutterBottom>
        h5, but it has not been defined
      </Typography>
      <Typography variant="h6" gutterBottom>
        h6, but it has not been defined
      </Typography>

      <Typography variant="body1" gutterBottom >
        This is just the main text "leipäteksti"
      </Typography>

      <Typography variant="body2" gutterBottom >
        This is just the main text "leipäteksti"
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        This is a subtitle. Bit larger text easier to read.
      </Typography>
      <Typography variant="subtitle2" gutterBottom my={4}>
        Here is a second paragraph with subtitle2 variant
      </Typography>
      <BiggerTypography>
        Here is a custom Typography which can be found at the beginning of this pages source code
      </BiggerTypography>

      <Typography variant="subtitle2" gutterBottom my={4}>
        You can create your custom variants or edit the current ones src/theme/typography.js (and the other files)
      </Typography>
    </Box>
  );
}

const BreadcrumbPath = () => {
  return (
    <>
      { /* Breadcrumbs is the path to the page in the beginning. This is good practice for usability */}
      <Breadcrumbs aria-label="Breadcrumb" mt={2} >
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/documentation/welcome">
          Documentation
        </Link>
        <Typography>Blank</Typography>
      </Breadcrumbs >
      { /* Breadcrumb ends here. Last element does not have the link as it is the page itself */}
    </>
  )
}

function Blank() {
  return (
    <React.Fragment>
      <Helmet title="Lakes - Profirator IoT Smart City Platform as Service" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Example page for document developers
          </Typography>
          <BreadcrumbPath />
          <Divider my={6} />
          <TypographyExamples />
          <Contents />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Blank
