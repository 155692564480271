import {
  Info,


} from "react-feather";

const policies = [
  {
    href: "/TermsOfService",
    icon: Info,
    title: "Terms of Service",
  },
  {
    href: "/PrivacyPolicy",
    icon: Info,
    title: "Privacy Policy",
  },
]

const documentationSection = [
  {
    href: "/documentation/welcome",
    icon: Info,
    title: "Welcome",
  },
  {
    href: "/documentation/solution",
    icon: Info,
    title: "Solution Templates - Node-RED Weather Observed",
  },
  {
    href: "/documentation/kubernetes",
    icon: Info,
    title: "Kubernetes",
  },
  {
    href: "/documentation/iotsmartcitylayer",
    icon: Info,
    title: "IoT Smart city layer",
  },
  {
    href: "/documentation/accesscontrollayer",
    icon: Info,
    title: "Access control layer",
  },
  {
    href: "/documentation/connectors",
    icon: Info,
    title: "Connectors",
  },
  {
    href: "/documentation/security",
    icon: Info,
    title: "Security",
  },{
    href: "/documentation/analytics",
    icon: Info,
    title: "Analytics",
  },
  {
    href: "/documentation/roadmap",
    icon: Info,
    title: "Roadmap",
  },
  {
    href: "/documentation/infrastructureascode",
    icon: Info,
    title: "Infrastructure as code",
  },

]


const navItems = [
  {
    title: "Documentation",
    pages: documentationSection
  },
  {
    title: "Privacy policy and Terms of Services",
    pages: policies
  },

];

export default navItems;
