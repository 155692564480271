import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Content = () => {
  return (
    <>

      <p align="right">
        Last updated: May 2022
      </p>
      <p>
        If you, the User, continue to browse and use this website, you are agreeing
        to comply with and be bound by the following Terms, govern the Platform's
        relationship with you in relation to this website.
      </p>
      <h1>
        1. About
      </h1>
      <Typography variant="subtitle1">
        1.1. Profi.space (hereinafter referred to as the "Platform") offers self-service data and
        Internet of Things ('IoT') platform services hosted by Profirator Oy, where
        users can connect the data sources like IoT and other internet services and
        visualise various dashboards and track the critical data points. Users can
        use the platform for a single data source or a full-fledged IoT platform
        where it is possible to open Application Programming Interfaces ('APIs')
        for developers for the data collected in the platform. Profi.space is based
        on NGSIv2 data and API standards.
      </Typography>
      <h1>
        2. General
      </h1>
      <Typography variant="subtitle1">
        2.1. <strong>Terms</strong>: These terms and any agreed additional terms
        include any subscription packages and pricing that will apply to your use
        of the Platform (hereinafter referred to as the "<strong>Terms</strong>")
        and govern your relationship with Profirator Oy and its subsidiaries (we,
        our, us, Platform) unless you, or the organisation you represent, has
        entered into a separate binding contract with us.
      </Typography>
      <Typography variant="subtitle1">
        2.2. <strong>Other Applicable Terms</strong>: This includes our
        <a href="PrivacyPolicy">
          Privacy Notice &amp; Cookies Policy
        </a>
        , which sets out: (a) the terms on which we process any personal data we
        collect from you, or that you provide to us; and (b) information about the
        use of cookies on the Platform, will also apply to your use of the
        Platform. By using the Platform, you consent to such processing and you
        warrant that all data provided by you is accurate.
      </Typography>
      <Typography variant="subtitle1">
        2.3. <strong>Changes to These Terms</strong>. From time to time we may
        update these Terms (including by way of additional terms or policies). We
        will notify you in writing of any material changes to our terms (including
        via email and pop-up notices on the Platform). If you continue to use the
        Platform following receipt of such notice, you will be deemed to have
        accepted the updated Terms.
      </Typography>
      <h1>
        3. Interpretation
      </h1>
      <Typography variant="subtitle1">
        3.1. In this Agreement, unless the context otherwise requires:
      </Typography>
      <Typography variant="body1">
        3.1.1. a reference to any document is a reference to that document as
        varied, novated or replaced from time to time;
      </Typography>
      <Typography variant="body1">
        3.1.2. the singular includes the plural and vice versa;
      </Typography>
      <Typography variant="body1">
        3.1.3. a reference to a gender includes all other genders;
      </Typography>
      <Typography variant="body1">
        3.1.4. a reference to a person or entity includes a natural person, a
        partnership, a corporation, trust, association, an unincorporated body,
        authority or other entity; and
      </Typography>
      <Typography variant="body1">
        3.1.5. a reference to a person includes that person's legal personal
        representative, successors and permitted assigns;
      </Typography>
      <Typography variant="subtitle1">
        3.2. Headings have been inserted for convenience only and shall not affect
        the interpretation of these Terms.
      </Typography>
      <h1>
        4. Definitions
      </h1>
      <Typography variant="subtitle1">
        4.1. In these Terms, unless the context clearly indicates otherwise, the
        following terms have the following meanings:
      </Typography>
      <Typography variant="body1">
        4.1.1. 'User' or 'you' means an individual or entity using the Platform's
        services.
      </Typography>
      <Typography variant="body1">
        4.1.2. 'User Assets' means images, audio/audio-visual content, scripts,
        screenplay, advertising or campaign materials, publications, music,
        documents, software, information, literary material, any logo, trademark or
        indicia, and/or other materials provided by the User, (including materials
        ordinarily subject to copyright, patent and is classified by the User as a
        trade secret or form of know-how and any information that the User regards
        as confidential information).
      </Typography>
      <Typography variant="body1">
        4.1.3. 'Confidential Information' means:
      </Typography>
      <ul>

        <li>
          <Typography variant="body">
            information relating to the User or our plans and potential
            opportunities in and with respect to its current or proposed products and
            services;
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            information relating to the User or our current or proposed products
            and services which is by its nature confidential or which is identified by
            the User or us to be confidential;
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            any information relating to the User;
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            information relating to the User or our business including without
            limitation information in relation to the User or our business plans or
            proposed business plans, marketing and promotional plans, formulae relating
            to the products or materials of the User or us, research information and
            analysis, trade secrets, business development and marketing strategies,
            sales data, organisational arrangements, contracts with other persons, User
            lists, financial data, information comprised in or relating to the
            financial position and assets or liabilities of the User or us.
          </Typography>
        </li>
      </ul>
      <Typography variant="body1">
        4.1.4. 'Contract' means the contract formed between a User and the Platform
        in accordance with the terms and conditions set out in these Terms.
      </Typography>
      <Typography variant="body1">
        4.1.5. 'Force Majeure Event' means any event beyond the affected parties'
        reasonable control, including, without limitation, strike, lockout or other
        industrial action, act of God (including severe weather events), act of
        terrorism, war, act of government, political or civil disturbance,
        epidemic, or pandemic.
      </Typography>
      <Typography variant="body1">
        4.1.6. 'Intellectual Property Rights' means all rights in or to any patent,
        copyright, moral rights, database rights, registered design or other design
        right, utility model, trade mark (whether registered or not and including
        any rights in get up or trade dress), brand name, service mark, trade name,
        domain name, company name, eligible layout right, chip topography right and
        any other rights of a proprietary nature in or to the results of
        intellectual activity in the industrial, commercial, scientific, literary,
        or artistic fields whether registrable or not and whenever existing in the
        world, including all renewals, extensions and revivals of, and all rights
        to apply for, any of the foregoing rights.
      </Typography>
      <Typography variant="body1">
        4.1.7. 'Operational Data' means any data that the User provides to the
        Platform;
      </Typography>
      <Typography variant="body1">
        4.1.8. 'Platform' means our platform located at lakes.profi.space,
        including our mobile application and similar.
      </Typography>
      <Typography variant="body1">
        4.1.9. 'Personal Data' means any information relating to an identified or
        identifiable natural person ('data subject'); an identifiable natural
        person is one who can be identified, directly or indirectly, in particular
        by reference to an identifier such as a name, an identification number,
        location data, an online identifier or to one or more factors specific to
        the physical, physiological, genetic, mental, economic, cultural or social
        identity of that natural person;
      </Typography>
      <Typography variant="body1">
        4.1.10. 'Privacy Notice &amp; Cookies Policy' means our privacy and cookies
        notice located at lakes.profi.space/privacypolicy
      </Typography>
      <Typography variant="body1">
        4.1.11. 'Services' shall refer to the services on the Website offered by
        the Platform as described in Section 1.1 of these Terms.
      </Typography>
      <Typography variant="body1">
        4.1.12. 'Taxes' means any value-added tax, sales tax, goods and services
        tax, withholding tax, or other tax, levy, duty, impost, charge or fee
        imposed on any transaction entered into through or in connection with the
        Platform that by applicable law must be added to any amounts charged for
        any participation, use or purchase related to the foregoing and must be
        collected by you, us or the relevant Creator.
      </Typography>
      <Typography variant="body1">
        4.1.13. 'Terms' means these terms of use, together with the documents
        referred to in them.
      </Typography>
      <Typography variant="body1">
        4.1.14. 'Website' means the Platform's website available on
        lakes.profi.space
      </Typography>
      <h1>
        5. Acceptance of Terms, Use, and Applicability
      </h1>
      <Typography variant="subtitle1">
        5.1. The Platform's services are offered subject to acceptance of all of
        the terms and conditions contained in these Terms including the Privacy
        &amp; Cookie Notice available at lakes.profi.space/provacypolicy and all
        other operating rules, policies, and procedures that may be published on
        the Website by the Platform or which are otherwise applicable in relation
        to the Services, which are incorporated by reference. These Terms apply to
        every user (registered or unregistered) of the Platform's Services. In
        addition, some services offered through the Services may be subject to
        additional terms (as specified by the Platform). Your use of those services
        is subject to those additional terms and conditions, which are incorporated
        into these Terms by this reference.
      </Typography>
      <Typography variant="subtitle1">
        5.2. The Platform reserves the right, at its sole discretion, to modify or
        replace these Terms by posting the updated terms on the Website. It is your
        responsibility to check the Terms periodically for changes. Your continued
        use of the Services following the posting of any changes to the Terms and
        Conditions constitutes acceptance of those changes.
      </Typography>
      <Typography variant="subtitle1">
        5.3. These Conditions are applicable to all Contracts, Services, and Offers
        made by the Platform.
      </Typography>
      <Typography variant="subtitle1">
        5.4. The Platform reserves the right to make alterations and/or additions
        to the Terms and Conditions at any time. The altered Terms will be made
        known to User and will be applicable from that moment on.
      </Typography>
      <Typography variant="subtitle1">
        5.5. If User consists of several legal entities or organisations, each will
        be responsible for the entire fulfilment of the Contract.
      </Typography>
      <Typography variant="subtitle1">
        5.6. Rules &amp; Regulations may apply alongside these Terms.
      </Typography>
      <Typography variant="subtitle1">
        5.7. As a condition of use, the User shall not use the Services for any
        purpose that is prohibited by the Terms or law. The User is responsible for
        all of its activity in connection with the Services. The User shall not
        permit any third party to use their account.
      </Typography>
      <h1>
        6. Registration
      </h1>
      <Typography variant="subtitle1">
        6.1. You may use elements of the Platform by simply visiting our website,
        but access to most of the Platform is available only to registered users.
      </Typography>
      <Typography variant="subtitle1">
        6.2. In order to register to use the Platform you must be at least 18 years
        of age. By registering to use the Platform, you represent and warrant that
        this is the case.
      </Typography>
      <Typography variant="subtitle1">
        6.3. You agree that you will provide truthful and accurate information when
        registering to use the Platform. The decision to accept a registration is
        in our discretion and we may revoke a registration at any time.
      </Typography>
      <Typography variant="subtitle1">
        6.4. You may not register to use the Platform if the User is already
        registered or if you were previously a registered user and we cancelled
        your account as a result of you being in breach of these Terms.
      </Typography>
      <Typography variant="subtitle1">
        6.5. The User is responsible for maintaining the confidentiality of your
        password, account information and any external review links generated by
        you, and the User is solely responsible for all activities that occur under
        your password, account or an external review link generated by you, and for
        any access to or use of the Platform by you or any person or entity using
        your password or an external review link generated by you, whether or not
        such access or use has been authorised by you.
      </Typography>
      <Typography variant="subtitle1">
        6.6. You must immediately notify us of any unauthorised use of your
        password or account, an external review link generated by you, or any other
        breach of security. We will not be liable for any loss or damage whatsoever
        resulting from the disclosure of your username and/or password contrary to
        these Terms.
      </Typography>
      <Typography variant="subtitle1">
        6.7. The User is responsible for ensuring that the information we hold
        about you is up to date. Please amend your details via the Platform as
        appropriate from time to time or contact us if you require assistance.
      </Typography>
      <h1>
        7. Fees and Payment
      </h1>
      <Typography variant="subtitle1">
        7.1. User must pay any applicable subscription fees applicable to any
        subscription package requested and accepted by the User.
      </Typography>
      <Typography variant="subtitle1">
        7.2. The User is obliged to pay to the amount due by the due date as
        specified via the Platform.
      </Typography>
      <Typography variant="subtitle1">
        7.3. Payment must be made in the method and currency stipulated via the
        Platform or applicable invoice (which may be updated from time to time).
      </Typography>
      <Typography variant="subtitle1">
        7.4. If User fails to make any payment due under the Contract by the due
        date for payment, then, without limiting our remedies, the service will be
        paused or terminated until payment is made, following which the service is
        then resumed.
      </Typography>
      <Typography variant="subtitle1">
        7.5. User agrees to make all payments under the Contract without
        withholding or deduction of, or in respect of, any Taxes unless required by
        law.
      </Typography>
      <h1>
        8. Intellectual Property
      </h1>
      <Typography variant="subtitle1">
        8.1. The User acknowledges that the Platform and all material published on
        the Platform including but not limited to text, graphics, photos, logos,
        button icons, images, trademarks, audio and audio visual clips, databases,
        data compilations, data and software (but excluding any content uploaded by
        users of the Platform) (hereinafter referred to as the Platform Content)
        are (as between you and us) owned and controlled by us, or licensed
        exclusively to us. You may not copy, adapt, display, communicate to the
        public or otherwise use any Platform Content except as enabled and
        permitted by the Platform from time to time.
      </Typography>
      <h1>
        9. Your Use of the Platform and Content Standards
      </h1>
      <Typography variant="subtitle1">
        9.1. The User is responsible for making all technical arrangements
        necessary to access the Platform. The User is also responsible for ensuring
        that all persons who access the Platform through your internet connection
        are aware of these Terms and that they comply with them.
      </Typography>
      <Typography variant="subtitle1">
        9.2. Without limitation, you may not use the Platform:
      </Typography>
      <Typography variant="body1">
        <strong>9.2.1. </strong>
        <strong>
          to connect any data sources which include Personal Data, and
          private/intimate information (such as, video streams from a bedroom)
          and/or which steam information that does not belong to the User (such
          as streams of activities of a neighbour's yard) - the User is not
          allowed to upload Personal Data as Operational Data;
        </strong>
        <strong></strong>
      </Typography>
      <Typography variant="body1">
        9.2.2. in any way that is unlawful or fraudulent, or has any unlawful or
        fraudulent purpose or effect;
      </Typography>
      <Typography variant="body1">
        9.2.3. to send, or procure the sending of, any unsolicited or unauthorised
        electronic communication, advertising or promotional material or any other
        form of similar solicitation (spam); or
      </Typography>
      <Typography variant="body1">
        9.2.4. to knowingly transmit any data or send or upload any material that
        contains viruses, Trojan horses, worms, time-bombs, keystroke loggers,
        spyware, adware or any other harmful programs or similar computer code
        designed to adversely affect the operation of the Platform or any computer
        software or hardware.
      </Typography>
      <Typography variant="subtitle1">
        9.3. You also agree not to:
      </Typography>
      <Typography variant="body1">
        9.3.1. reproduce, duplicate, copy or re-sell any part of the Platform (or
        any content within it) in contravention of these Terms; and
      </Typography>
      <Typography variant="body1">
        9.3.2. access without authority, interfere with, damage or disrupt: (A) any
        part of the Platform; (B) any equipment or network on which the Platform is
        stored; (C) any software used in the provision of the Platform; or (D) any
        equipment or network or software owned or used by any third party.
      </Typography>
      <Typography variant="subtitle1">
        9.4. Whenever you make use of the Platform to allow you to upload content
        or make contact with other users of the Platform (each a Communication) you
        grant us a non-exclusive, royalty-free, worldwide, perpetual licence to
        host, copy, store and make available such Communication and agree to comply
        with the Content Standards.
      </Typography>
      <h1>
        10. Privacy
      </h1>
      <Typography variant="subtitle1">
        10.1. You agree that any and all personal information you provide to us via
        this website may be collected, stored, processed and used in accordance
        with our current Privacy Notice &amp; Cookies Policy (accessible online at
        lakes.profi.space/privacypolicy ).
      </Typography>
      <h1>
        Copyright Infringement - Takedown Procedures
      </h1>
      <Typography variant="subtitle1">
        10.2. If the User believes that any materials on the Platform infringe its
        copyright, you may request that such materials be removed. This request
        must bear a signature (or electronic equivalent) of the copyright holder or
        an authorised representative, and must:
      </Typography>
      <Typography variant="body1">
        10.2.1. identify the allegedly infringing materials;
      </Typography>
      <Typography variant="body1">
        10.2.2. indicate where on the Platform the infringing materials are
        located;
      </Typography>
      <Typography variant="body1">
        10.2.3. provide your name and contact information;
      </Typography>
      <Typography variant="body1">
        10.2.4. state that you have a good faith belief that the materials are
        infringing;
      </Typography>
      <Typography variant="body1">
        10.2.5. state that the information in your claim is accurate; and
      </Typography>
      <Typography variant="body1">
        10.2.6. indicate that the User is the lawful copyright owner or are
        authorised to act on the owner's behalf. If you believe that someone has
        wrongly filed a notice of copyright infringement against you, you may send
        us a counter-notice in accordance with equivalent requirements. Our contact
        for copyright issues relating to the Platform (including, but not limited
        to, the notices and counter-notices) is lakes@profirator.fi. Please note
        that in many jurisdictions it is an offence to issue a false or misleading
        request or notice to take down allegedly infringing materials. Penalties
        and/or civil remedies may apply.
      </Typography>
      <h1>
        11. Availability of the Platform
      </h1>
      <Typography variant="subtitle1">
        11.1. To the maximum extent permitted by law, the Platform is provided on
        an "as is" basis without any warranties of any kind. We make no
        representations, warranties or guarantees of any kind regarding the
        availability or operation of the Platform or that the Platform will be
        secure, uninterrupted or free of defects.
      </Typography>
      <Typography variant="subtitle1">
        11.2. Your access to the Platform may be suspended or restricted
        occasionally to allow for maintenance, repairs, upgrades, or the
        introduction of new facilities or services. We will always try to limit the
        frequency and duration of any planned disruption but we will not be liable
        to you if for any reason the Platform is unavailable at any time or for any
        period.
      </Typography>
      <h1>
        12. No Reliance on Information
      </h1>
      <Typography variant="subtitle1">
        12.1. Although we make reasonable efforts to update the information on the
        Platform, we make no representations, warranties or guarantees, whether
        express or implied, that the content on the Platform is accurate, complete
        or up to date.
      </Typography>
      <h1>
        13. Links to Other Platforms or the Platform
      </h1>
      <Typography variant="subtitle1">
        13.1. You may link to our home page, provided you do so in a way that is
        fair and legal and does not damage our reputation or take advantage of it.
        You must not establish a link in such a way as to suggest any form of
        association, approval or endorsement on our part where none exists. You
        must not establish a link to the Platform in any website or application
        that is not owned by you (save where expressly authorised under these
        Terms). The Platform must not be framed on any other website or
        application, nor may you create a link to any part of the Platform other
        than the home page (save where expressly authorised under these Terms).
      </Typography>
      <Typography variant="subtitle1">
        13.2. We reserve the right to withdraw linking permission without notice.
      </Typography>
      <h1>
        14. Computer Viruses
      </h1>
      <Typography variant="subtitle1">
        14.1. We recommend that you ensure that computers and other devices used to
        access the Platform run up-to-date anti-virus software as a precaution, and
        The User is advised to virus-check any materials downloaded from the
        Platform and regularly check for the presence of viruses and other
        malicious code.
      </Typography>
      <Typography variant="subtitle1">
        14.2. We will not be liable for any loss or damage caused by a virus or
        other technologically harmful material that may infect your computer
        equipment, computer programs, data, or other proprietary material due to
        your use of the Platform or to your downloading of any content from it, or
        from any website linked to it.
      </Typography>
      <h1>
        15. Limitation of Liability - PLEASE READ THIS SECTION CAREFULLY
      </h1>
      <Typography variant="subtitle1">
        15.1. Nothing in these Terms excludes or limits our liability for liability
        that cannot be excluded or limited under applicable law.
      </Typography>
      <Typography variant="subtitle1">
        15.2. It is a condition of accessing the Platform that you accept and agree
        we shall not be liable for any harmful effect that accessing the Platform
        may have on you, and you agree that you access and use the Platform
        entirely at your own risk.
      </Typography>
      <Typography variant="subtitle1">
        15.3. Without limitation to the generality of the foregoing, whether in
        contract, tort (including, without limitation, negligence), breach of
        statutory duty, or otherwise:
      </Typography>
      <Typography variant="body1">
        15.3.1. we will not be liable to you for any loss or damage arising under
        or in connection with:
      </Typography>
      <Typography variant="body">
        &#183; use of, or inability to use, the Platform;
      </Typography>
      <Typography variant="body">
        &#183; use of or reliance on any content displayed on or produced via the
        Platform;
      </Typography>
      <Typography variant="body">
        &#183; the acts or omissions of any other user of the Platform; or
      </Typography>
      <Typography variant="body">
        &#183; any liability whatsoever arising in relation to the production of a
        Production;
      </Typography>
      <Typography variant="body1">
        15.3.2. we will have no liability to you for any indirect or consequential
        losses of any kind or for any loss of revenue, profit, business
        opportunity, contracts, data or goodwill (whether direct or indirect)
        arising out of or in connection with these Terms and/or the Platform; and
      </Typography>
      <Typography variant="subtitle1">
        15.4. Except as expressly set out in these Terms and to the maximum extent
        permitted by law, we make no representations, warranties or guarantees of
        any kind in respect of the Platform or any content available through the
        Platform and all conditions, warranties and representations express or
        implied are hereby excluded to the full extent permitted by law.
      </Typography>
      <h1>
        16. Indemnification
      </h1>
      <Typography variant="subtitle1">
        16.1. The User agrees to defend, indemnify, and hold harmless the Platform,
        its affiliates, and each of its and its affiliates' employees, contractors,
        directors, suppliers, and representatives, from all liabilities, claims,
        and expenses, including reasonable attorneys' fees and other legal costs,
        that arise from or relate to your use or misuse of, or access to, the
        Services and the Platform, or otherwise from a breach of these Terms, or
        infringement by you, or any third party using your account, of any
        intellectual property or other right of any person or entity. We reserve
        the right to assume the exclusive defence and control of any matter
        otherwise subject to this indemnification clause, in which case you agree
        that you'll cooperate and help us in asserting any defences.
      </Typography>
      <h1>
        17. Termination or Suspension
      </h1>
      <Typography variant="subtitle1">
        17.1. We may, in our sole discretion, terminate or temporarily suspend your
        password, account (or any part thereof) or use of the Platform without
        notice if The User is in breach of these Terms or if we believe in our
        reasonable discretion that your use of the Platform is unsuitable in any
        way.
      </Typography>
      <h1>
        18. Force Majeure Event
      </h1>
      <Typography variant="subtitle1">
        18.1. The Platform is not obligated to fulfil the Contract if prevented
        from doing so as a result of circumstances beyond its control. The Platform
        can also appeal to force majeure if a Force Majeure Event occurs after the
        Platform should have met its obligations.
      </Typography>
      <Typography variant="subtitle1">
        18.2. If the deficiencies as a result of a Force Majeure Event are of a
        temporary nature, the Platform may suspend its obligations until the Force
        Majeure Event ceases to exist, without being obliged to any form of damage
        compensation. The Platform reserves the right in the event of force majeure
        to claim payment from User for work already carried out by the Platform.
      </Typography>
      <Typography variant="subtitle1">
        18.3. The deficiencies as a result of force majeure give User the right to
        dissolve the Contract and withdraw from using the Platform's service.
      </Typography>
      <h1>
        19. Assignment
      </h1>
      <Typography variant="subtitle1">
        19.1. We may at any time assign, mortgage, charge, subcontract, sublicense
        or otherwise transfer any or all of our rights and obligations under these
        Terms.
      </Typography>
      <Typography variant="subtitle1">
        19.2. You may not assign, mortgage, charge, subcontract, sublicense or
        otherwise transfer any of your rights or obligations under these Terms
        without our prior written consent.
      </Typography>
      <h1>
        20. Applicable Law and Jurisdiction
      </h1>
      <Typography variant="subtitle1">
        20.1. All Contracts made between the Platform and User are governed by the
        laws of Finland, unless otherwise agreed upon.
      </Typography>
      <Typography variant="subtitle1">
        20.2. Disputes shall, at the choice of the Platform, be settled by the
        courts of Finland.
      </Typography>
      <h1>
        21. Miscellaneous
      </h1>
      <Typography variant="subtitle1">
        21.1. If any provision or part of a provision of these Terms is held to be
        illegal, invalid, unenforceable or against public policy pursuant to a
        final adjudication by a court of competent jurisdiction, such provision
        will be deemed severed from these Terms and the remainder of these Terms
        will remain in full force and effect.
      </Typography>
      <Typography variant="subtitle1">
        21.2. These Terms, and the documents referred to in them, constitute the
        entire agreement between you and us in relation to the subject matter of
        these Terms and supersede and extinguish any prior agreement in respect of
        the same.
      </Typography>
      <Typography variant="subtitle1">
        21.3. Nothing in these Terms shall limit our right to bring a suit, action
        or proceeding (Proceeding) against you in the courts of any country in
        which you have assets or in any other court of competent jurisdiction nor
        shall Proceedings we institute in any one or more jurisdictions preclude us
        from instituting Proceedings in any other jurisdiction (whether
        concurrently or not) if and to the extent permitted by applicable law.
      </Typography>
    </>
  )
}



function Welcome() {
  return (
    <React.Fragment>
      <Helmet title="Getting Started" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Terms of Service
          </Typography>

          <Divider my={6} />

          <Content />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Welcome;
