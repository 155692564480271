import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search topics…",
      "Welcome back": "Welcome back",
      "We've missed you": "We've missed you",
      "Let's get back to work": "Let's get back to work"
    },
  },
  fi: {
    translation: {
      Search: "Etsi aiheita...",
      "Welcome back": "Tervetuloa takaisin",
      "We've missed you": "Olemme ikävöineet sinua",
      "Let's get back to work": "Töihin siitä"
    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Welcome back": "Willkommen zurück",
      "We've missed you": "Wir haben dich vermisst",
      "Let's get back to work": "Let's get back to work"

    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "fi",
  interpolation: {
    escapeValue: false,
  },
});
