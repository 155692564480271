import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import Code from "../../components/Code";
import YoutubeEmbed from "../../components/youtube/YoutubeEmbed";


const GrafanaChart = ({ link, minWidth, minHeight }) => {
  return (
    <Grid item xs={6} sm={12} md={6} lg={4} style={{
      minWidth: minWidth,
      minHeight: minHeight,
      width: minWidth,
      height: minHeight,
      margin: 0,
      padding: 0,
    }}>
      <iframe title="grafana" src={link} frameBorder="0" width="100%" height="100%"></iframe>
    </Grid>
  )
}



const BiggerTypography = styled(MuiTypography)`
  font: 1em
`
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);


const Example1 = () => {
  return (
    <Box mb={10}>
      <Typography variant="body1" gutterBottom>
        We are not ready! This page is under constrution. I'm sure it will be ready in no time, so please visit later. Or follow the announcements in our
        <a href="https://lakes-support-chat.profirator.fi/channel/general"> chat support </a>.
        You may find our <a href="https://www.youtube.com/channel/UCMqPMiYePYnzG-KEuv82HZw/videos">Youtube</a> videos interesting.
      </Typography>
    </Box>
  )
}


function Contents() {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Sorry to disappoint you, but we are not ready.
      </Typography>
      <Example1 />
    </>
  );
}


const BreadcrumbPath = () => {
  return (
    <>
      { /* Breadcrumbs is the path to the page in the beginning. This is good practice for usability */}
      <Breadcrumbs aria-label="Breadcrumb" mt={2} >
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/documentation/welcome">
          Documentation
        </Link>
        <Typography>Solution</Typography>
      </Breadcrumbs >
      { /* Breadcrumb ends here. Last element does not have the link as it is the page itself */}
    </>
  )
}

function DRoadmap() {
  return (
    <React.Fragment>
      <Helmet title="Lakes - Profirator IoT Smart City Platform as Service" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Oh no! - We are not ready yet
          </Typography>
          <BreadcrumbPath />
          <Divider my={6} />
          <Contents />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DRoadmap
