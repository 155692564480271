import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import Code from "../../components/Code";
import YoutubeEmbed from "../../components/youtube/YoutubeEmbed";


const GrafanaChart = ({ link, minWidth, minHeight }) => {
  return (
    <Grid item xs={6} sm={12} md={6} lg={4} style={{
      minWidth: minWidth,
      minHeight: minHeight,
      width: minWidth,
      height: minHeight,
      margin: 0,
      padding: 0,
    }}>
      <iframe title="grafana" src={link} frameBorder="0" width="100%" height="100%"></iframe>
    </Grid>
  )
}



const BiggerTypography = styled(MuiTypography)`
  font: 1em
`
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);


const Example1 = () => {
  return (
    <Box mb={10}>
      <Typography variant="body1" gutterBottom>
        We have been asked for examples on how to connect data sources to our platform. We decided to give you a real example. This Node-RED flow we are presenting here is the same flow we are using to collect weather data in our
        https://lakes.profi.space. If you cant wait, visit https://nr.profi.space/ and login with these credentials. This is a red only user, and you cannot ruin anything:
      </Typography>
      <Typography variant="body1" gutterBottom>
          Username: demo-reader
          Password: lDQ2KMuuqZy42gH2MTOT
      </Typography>

      <Typography variant="body1">
        We are taking the data from Open Weather Map and converting that into NGSI-LD format. Then we push it to Scorpio. Scorpio is an NGSI-LD broker, it stored the data to PostgreSQL database. From there it is easy to
        visualise that data. If you want to see your seld, please create an account and log in!
      </Typography>
    </Box>
  )
}


function Contents() {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Node-RED based Weather Observed Example
      </Typography>
      <Example1 />
    </>
  );
}


const BreadcrumbPath = () => {
  return (
    <>
      { /* Breadcrumbs is the path to the page in the beginning. This is good practice for usability */}
      <Breadcrumbs aria-label="Breadcrumb" mt={2} >
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/documentation/welcome">
          Documentation
        </Link>
        <Typography>Solution</Typography>
      </Breadcrumbs >
      { /* Breadcrumb ends here. Last element does not have the link as it is the page itself */}
    </>
  )
}

function Solution() {
  return (
    <React.Fragment>
      <Helmet title="Lakes - Profirator IoT Smart City Platform as Service" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Solution Examples - Weather Observed with Node-RED
          </Typography>
          <BreadcrumbPath />
          <Divider my={6} />
          <Contents />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Solution
