
const setSession = (user) => {
  if (user) {
    sessionStorage.setItem("user", user);
  
  } else {
    sessionStorage.removeItem("user");
  }
};

export { setSession };
